import styled, { createGlobalStyle } from 'styled-components';
import { primaryColor } from './index';
export default createGlobalStyle`
// 悬浮小手
  .cursor {
    cursor: pointer !important;
    &:hover {
      opacity: 0.7 !important;
    }
  }
  .flex{
    display: flex;
  }

  .flex-wrap {
   flex-wrap: wrap;
  }
  .flex-item-center {
    display: flex;
    align-items: center;
  };
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  };

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  };

  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-cloumn-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
