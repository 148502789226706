import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { useActiveWeb3React } from '@/hooks';
import { AppDispatch } from '@/state';
import { clearAllTransactions } from '@/state/transactions/actions';
import { AutoRow } from '../Row';
import Copy from '@/components/CopyAddress/Copy';
import Transaction from './Transaction';
import { useTranslation } from 'react-i18next';
import { getEtherscanLink } from '@/utils';
import { injected, walletlink } from '@/connectors';
import { ButtonSecondary } from '../Button';
import { ExternalLink as LinkIcon } from 'react-feather';
import { ExternalLink, LinkStyledButton, TYPE } from '@/theme';
import { addressConvert } from '@/utils/fbAddress';
import { RowFixed } from '../Row';
import { AutoColumn } from '../Column';
import { ButtonPrimary } from '@/components/Button';
import { useETHBalances } from '@/state/wallet/hooks';
import { Text } from 'rebass';
import { ChainId } from 'aiswap-sdk';
import empty_img from '@/assets/images/icon/empty.png';

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.FIBO]: 'FIBO',
  [ChainId.OKS]: 'OK_test',
};

const ChangeBtn = styled(ButtonPrimary)`
  margin-top: 10px;
  height: 50px;
  font-size: 15px;
`;

const HeaderRow = styled.span<{ active: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap};
  font-weight: 500;
  color: ${({ theme, active }) => (active ? theme.primary1 : '#B9B9B9')};
  margin-bottom: 15px;
  font-weight: 600;
  display: block;
  flex: 1;
  text-align: center;
`;

const UpperSection = styled.div`
  position: relative;
  padding: 12px 13px;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const InfoCard = styled.div`
  padding: 1rem;
  /* border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px; */
  background-color: ${({ theme }) => theme.primary5};
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  max-width: 320px;
  & > span {
    width: 92%;
    overflow: hidden;
  }
`;

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  flex-grow: 1;
  overflow: auto;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`;

const AccountControl = styled.div`
  display: flex;
  justify-content: center;
  min-width: 0;
  width: 100%;
  font-weight: 500;
  font-size: 1.25rem;
  a {
    color: ${({ theme }) => theme.primary1};
  }
  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`;

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`;

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />;
      })}
    </TransactionListWrapper>
  );
}

interface AccountDetailsProps {
  toggleWalletModal: () => void;
  pendingTransactions: string[];
  confirmedTransactions: string[];
  ENSName?: string;
  openOptions: () => void;
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);
  const userEthBalance = useETHBalances(account ? [account] : [])?.[
    account ?? ''
  ];

  const tabList = [
    {
      label: t('Wallet'),
      value: 'wallet',
    },
    {
      label: t('Transactions'),
      value: 'record',
    },
  ];
  const [tabKey, setTabKey] = useState(tabList[0].value);

  return (
    <>
      <UpperSection>
        <div className="flex-between">
          {tabList.map(item => {
            return (
              <HeaderRow
                key={item.value}
                onClick={() => {
                  setTabKey(item.value);
                }}
                active={tabKey === item.value}
                className="cursor"
              >
                {item.label}
              </HeaderRow>
            );
          })}
        </div>
        {tabKey === 'wallet' && (
          <AutoColumn gap="sm">
            <RowFixed>{t('Address')}</RowFixed>
            <InfoCard>
              {ENSName ? (
                ENSName
              ) : (
                <>
                  <span>{account && addressConvert(account).fibo}</span>
                  {account && <Copy toCopy={addressConvert(account).fibo} />}
                  
                </>
              )}
            </InfoCard>

            <InfoCard>
              {ENSName ? (
                ENSName
              ) : (
                <>
                  <span>{account}</span>
                  {account && <Copy toCopy={account} />}
                  
                </>
              )}
            </InfoCard>

            {account && chainId && userEthBalance && NETWORK_LABELS[chainId] && (
              <>
                <div className="flex-between">
                  FIBO {t('balance')}
                  <Text pl="0.75rem" pr="0.5rem" fontWeight={500}>
                    {userEthBalance?.toSignificant(4)}{' '}
                  </Text>
                </div>
              </>
            )}

            <div>
              {connector !== injected && connector !== walletlink && (
                <WalletAction
                  style={{
                    fontSize: '.825rem',
                    fontWeight: 400,
                    marginRight: '8px',
                  }}
                  onClick={() => {
                    (connector as any).close();
                  }}
                >
                  Disconnect
                </WalletAction>
              )}

              <ChangeBtn
                onClick={() => {
                  openOptions();
                  toggleWalletModal();
                }}
              >
                {t('Change')}
              </ChangeBtn>
            </div>

            <AccountControl>
              {chainId && account && (
                <AddressLink
                  hasENS={!!ENSName}
                  isENS={false}
                  href={getEtherscanLink(chainId, account, 'address')}
                >
                  <span style={{ marginRight: '4px' }}>View on Fiboscan</span>
                  <LinkIcon size={16} />
                </AddressLink>
              )}
            </AccountControl>
          </AutoColumn>
        )}
        {tabKey === 'record' && (
          <>
            {/* {true ? ( */}
            {!!pendingTransactions.length || !!confirmedTransactions.length ? (
              <LowerSection>
                <AutoRow
                  mb={'1rem'}
                  style={{ justifyContent: 'space-between' }}
                >
                  <TYPE.body>{t('Recent Transactions')}</TYPE.body>
                  <LinkStyledButton onClick={clearAllTransactionsCallback}>
                    ({t('clear all')})
                  </LinkStyledButton>
                </AutoRow>
                {renderTransactions(pendingTransactions)}
                {renderTransactions(confirmedTransactions)}
              </LowerSection>
            ) : (
              <LowerSection className="flex">
                <div className="flex-center" style={{ fontSize: '14px' }}>
                  <img src={empty_img} style={{ width: '20px' }} />
                  {t('Your transactions will appear here...')}
                </div>
              </LowerSection>
            )}
          </>
        )}
      </UpperSection>
    </>
  );
}
