import { ChainId, Token, ETHER } from 'aiswap-sdk';
import { poolFactoryType } from '@/constants';

export const USDT = new Token(
  ChainId.FIBO,
  '0xffCcf0f2a9DE309B69dB326C01742f6540cbF450', //'0xe27a02eaec30004d7f9c63c3a86d8ac27c8b0d61',
  18,
  'USDT',
  'USDT',
);

export const FLY = new Token(
  ChainId.FIBO,
  '0x4FF286F0B97c2c578f35f99adbe3542fd14f042C',
  8,
  'FLY',
  'FLY Swap Token',
);

export const Ai = new Token(
  ChainId.FIBO,
  '0x605B0cdf664e6a6C7aa1771Ff77bEFf1F35278a5',
  8,
  'AI',
  'AI Swap Token',
);

export const FUSDToken = '0xbb1d5c55ce373943a571c68da6e7df0610254e2d';
export const FUSD = new Token(ChainId.FIBO, FUSDToken, 18, 'FUSD', 'F USDT');

const WFIBO = new Token(
  ChainId.FIBO,
  '0xe3DE253AA18D949273aFbCa44053ecB66424Ac4a',
  18,
  'FIBO',
  'Wrapped Fibo',
);

export const FFIBO = new Token(
  ChainId.FIBO,
  '0x2Df800f6D6185324dAfeD4544133fa2F87deCD59', 
  18,
  'FFIBO',
  'Wrapped FFIBO',
);

export const FFLY = new Token(
  ChainId.FIBO,
  '0x0546993878BD95000cba38082FBB961abDbC3ebA',
  18,
  'FFLY',
  'Wrapped FFLY',
);

export const HHC = new Token(
  ChainId.FIBO,
  '0x9617bb33fe7f9aa04d9caaf72505967840dda0da',
  18,
  'HHC',
  'Wrapped HHC',
);

const uList = [FUSD.address, USDT.address]; // U.FU

export const isFIBO = (token: any) => {
  return token?.symbol === ETHER.symbol;
};
export const isMappingType = (tokenA: any, tokenB: any) => {
  if (
    (uList.includes(tokenA?.address) && uList.includes(tokenB?.address)) ||
    (tokenA?.address === FFIBO.address && isFIBO(tokenB)) ||
    (tokenA?.address === FFLY.address && tokenB?.address === FLY.address)
  ) {
    return true;
  } else {
    return false;
  }
};

export type farmsType = {
  pid: number;
  lpSymbol: string;
  // lpToken?: string;
  quoteToken?: Token;
  token?: Token;
};

export const farmsList: farmsType[] = [
  // {
  //   pid: 0,
  //   lpSymbol: 'FIBO-FLY',
  //   quoteToken: WFIBO,
  //   token: Ai,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'FIBO-FUSD',
  //   quoteToken: WFIBO,
  //   token: FUSD,
  // },
];
