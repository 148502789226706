import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { ItemName } from '@/newPages/farms/pledge/module/redeem';
import { NumericalInputBox } from '@/newPages/farms/pledge/module/pledge';
import { useTranslation } from 'react-i18next';
import { useLpMappingInfo, useLpMappingList } from './hooks';
import BalanceBox from '../component/Balance';
import TokenNum from '../component/TokenNum';
import { BigNumber } from 'bignumber.js';
import Slider from '@/components/Slider';
import FooterBtn from './footerBtn';
import { BodyWrapper } from '@/pages/AppBody';

const CardWrap = styled(BodyWrapper)`
  max-width: none;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
`;
export const Card = styled.div<{
  width?: string;
}>`
  min-height: 200px;
  width: ${({ width }) => (width ? width : 'auto')};
  border-radius: 16px;
  color: ${({ theme }) => theme.primary1};
  overflow: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: auto
  `};

  .content {
    background-color: ${({ theme }) => theme.bg1};
  }
  & .content-list {
    border-radius: 25px;
    max-height: 100vh;
    overflow: auto;
  }
  .li {
    display: flex;
    flex-wrap: wrap;
    & div {
      flex: 1;
      margin: auto;
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
    & div {
      flex: none;
      width: 50%;
    }
  `};
  }
`;
const Header = styled.div`
  height: 79px;
  background: ${({ theme }) => (theme.darkMode ? theme.bg1 : `#F7F8FA`)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 31px;
  font-size: 24px;
  font-weight: bold;
`;

export const ModalViewBox = (props: any) => {
  const { t } = useTranslation();
  const { title = t('historical bill'), select = <></>, width = '' } = props;
  return (
    <Card width={width} className="card">
      <CardWrap>
        {title && (
          <Header className="header">
            <span>{title}</span>
            {select}
          </Header>
        )}
        <div style={{ overflow: 'auto' }} className="content">
          {props.children}
        </div>
      </CardWrap>
    </Card>
  );
};

const ItemNameView = styled(ItemName)`
  font-size: 18px;
  margin-bottom: 8px;
  .balance {
    font-size: 18px;
  }
`;
const InputNumBox = styled.div`
  margin-top: 26px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  padding: 26px 32px;
  .select-area {
    width: 100%;
    z-index: 2;
    .css-13cymwt-control {
      background: ${({ theme }) => theme.secondary3};
      border: none;
      height: 46px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px;
    }
    .css-1dimb5e-singleValue {
      color: ${({ theme }) => theme.primary1};
    }
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
`;

const Percentage = styled.span`
  font-size: 24px;
  font-weight: bold;
`;
interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}

const MappingView: React.FC<IndexType> = () => {
  const { t } = useTranslation();
  const [mappingNum, setMappingNum] = useState<string>(''); // 账单ID
  const [mappingData, setMappingType] = useState<any>(''); // 选择的lp
  const { list, loading: MappingListLoading } = useLpMappingList();

  // 余额
  const balance = BigNumber(mappingData?.from_balanceOf)
    .div(Math.pow(10, mappingData?.from_decimals))
    .toString();

  const setNum = (num: number) => {
    return num && balance
      ? parseInt(((num / Number(balance)) * 100).toString())
      : 0;
  };

  const { loading, mappingTokens }: any = useLpMappingInfo(mappingData) || {};

  const disabled = !mappingData || loading;

  return (
    <ModalViewBox title={t('Token Swap')} width="570px">
      <Content>
        <div>
          <div className="flex-between">
            <ItemNameView>{t('select token')}</ItemNameView>
            {mappingData && (
              <ItemNameView>
                {t('balance')}
                {/* <BalanceBox balance={balance} /> */}
                {mappingData?.from_balanceOf && (
                  <TokenNum address={mappingData.from_token || ''} />
                )}
                {mappingData?.from_symbol}
              </ItemNameView>
            )}
          </div>
          <Select
            options={list}
            placeholder={t('Please select')}
            onChange={(t: any) => {
              setMappingType(t);
              setMappingNum('');
            }}
            className="select-area"
            isDisabled={loading}
            isLoading={MappingListLoading}
          />
        </div>
        <InputNumBox>
          <ItemNameView>{t('Quantity')}</ItemNameView>
          <NumericalInputBox
            placeholder={t('input')}
            value={mappingNum}
            onUserInput={setMappingNum}
            disabled={disabled || !(Number(balance) > 0)}
          />
        </InputNumBox>
        <>
          <Percentage>{setNum(Number(mappingNum))}%</Percentage>
          <Slider
            value={setNum(Number(mappingNum))}
            onChange={v => {
              const Num = BigNumber(v)
                .times(balance)
                .div(100)
                .toFixed(18);
              setMappingNum(Num);
            }}
            disabled={disabled || !(Number(balance) > 0)}
          />
        </>
        {mappingData && mappingNum && (
          <ItemNameView>
            {t('amount can be obtained after mapping', {
              name: mappingData?.to_symbol,
            })}
            <BalanceBox
              balance={BigNumber(mappingNum)
                .times(Math.pow(10, mappingData.from_decimals))
                .times(mappingData.mappingRate)
                .div(1e18)
                .div(Math.pow(10, mappingData.to_decimals))
                .toFixed()}
            />
          </ItemNameView>
        )}
        <br />
        <FooterBtn
          disabled={disabled || !mappingNum}
          mappingData={mappingData}
          onClick={() => {
            mappingTokens(mappingNum, () => setMappingNum(''));
          }}
          balance={mappingNum}
        />
      </Content>
    </ModalViewBox>
  );
};
export default MappingView;
