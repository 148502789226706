import React, { memo, useMemo } from 'react';
import { useCurrency } from '@/hooks/Tokens';
import { useDerivedMintInfo } from '@/state/mint/hooks';
import { useTotalSupply } from '@/data/TotalSupply';
import { JSBI, TokenAmount, Token, ChainId } from 'aiswap-sdk';
import { tryParseAmount } from '@/state/swap/hooks';
import { useTradeExactIn } from '@/hooks/Trades';
import { FUSD, USDT } from '@/newPages/1230';
import Loader from '@/components/Loader';
import Balance from '../../component/Balance';
import { isFiboToken } from '@/newPages/farms/utils';
import { BigNumber } from 'bignumber.js';

export const getFarmQuoteToken = (token: any): any => {
  let usdt = FUSD;
  if (token?.currency.symbol === FUSD.symbol) {
    usdt = USDT;
  }
  return [token ?? undefined, usdt];
};

export const useLptokenNum = (data: any, balance: string) => {
  const quoteToken = useCurrency(isFiboToken(data.quoteToken)); // a
  const token: any = useCurrency(isFiboToken(data.token)); // b

  const { pair } = useDerivedMintInfo(
    quoteToken ?? undefined,
    token ?? undefined,
  );

  const totalSupply: any = useTotalSupply(pair?.liquidityToken); // 总流动性
  const amount = JSBI.BigInt(balance); // lp总流动性

  const userPoolBalance = new TokenAmount(
    new Token(ChainId.FIBO, data.lpToken, data.decimals, data.lpSymbol),
    amount,
  );

  // 总lp数量换算出俩种token分别是多少枚
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalSupply &&
    !!userPoolBalance &&
    JSBI.greaterThanOrEqual(totalSupply.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalSupply,
            userPoolBalance,
            false,
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalSupply,
            userPoolBalance,
            false,
          ),
        ]
      : [undefined, undefined];

  const parsedAmount0 = tryParseAmount('1', quoteToken ?? undefined); // 输入数量
  const parsedAmount1 = tryParseAmount('1', token ?? undefined); // 输入数量

  // 换算token对usdt的价格
  const bestTradeExactIn0 = useTradeExactIn(
    ...getFarmQuoteToken(parsedAmount0),
  ); // 数量对比目标token
  const bestTradeExactIn1 = useTradeExactIn(
    ...getFarmQuoteToken(parsedAmount1),
  );

  // console.log(
  //   `token0: ${Number(token0Deposited?.toFixed()) *
  //     Number(bestTradeExactIn0?.executionPrice.toFixed())}`,
  //   `token1:${Number(token1Deposited?.toFixed()) *
  //     Number(bestTradeExactIn1?.executionPrice.toFixed())}`,
  //   token0Deposited?.toFixed(),
  //   token1Deposited?.toFixed(),
  //   `输入金额:${bestTradeExactIn0?.inputAmount.toFixed()}`,
  //   `执行价格:${bestTradeExactIn0?.executionPrice.toFixed()}`,
  //   `下一个中间价:${bestTradeExactIn0?.nextMidPrice.toFixed()}`,
  //   `输出价格:${bestTradeExactIn0?.outputAmount.toFixed()}`,
  //   `价格影响:${bestTradeExactIn0?.priceImpact.toFixed()}`,
  // );

  return useMemo(() => {
    if (
      !token0Deposited ||
      !token1Deposited ||
      !bestTradeExactIn0 ||
      !bestTradeExactIn1
    ) {
      return { loading: true };
    }
    return {
      token0Deposited,
      token1Deposited,
      bestTradeExactIn0,
      bestTradeExactIn1,
      // 金额
      Price: BigNumber(token0Deposited.toSignificant())
        .times(bestTradeExactIn0.executionPrice.toSignificant())
        .plus(
          BigNumber(token1Deposited?.toSignificant()).times(
            bestTradeExactIn1?.executionPrice.toSignificant(),
          ),
        ),
    };
  }, [token0Deposited, token1Deposited, bestTradeExactIn0, bestTradeExactIn1]);
};
const TvlView = memo(({ data }: any) => {
  const { loading, Price } = useLptokenNum(data, data.lpBalanceOf.toString());

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {/* <Balance balance={Price?.toString() || '0'} icon="$" /> */}
      {Price?.toString()}
    </>
  );
});
export default TvlView;
