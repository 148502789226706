import React, { useEffect } from 'react';
import styled from 'styled-components';
import Redeem from './module/redeem';
import Pledge from './module/pledge';
import { useHistory, useParams } from 'react-router-dom';
import { farmsList } from '@/newPages/1230';
import Header from './module/header';
import { useTranslation } from 'react-i18next';
import { BodyWrapper } from '@/pages/AppBody';

const PoolView = styled(BodyWrapper)`
  padding: 26px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
  color: ${({ theme }) => theme.primary1};
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 39px;
`;

const Footer = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.primary1};
  margin-top: 16px;
`;

const PoolType: any = {
  stake: 'stake',
  redeem: 'redeem',
};

const Pool = () => {
  const params: any = useParams();
  const { pid, type, billId } = params || {};
  const { replace } = useHistory();
  const { t } = useTranslation();

  const getLp: any = () => farmsList.find(item => item.pid === Number(pid));

  useEffect(() => {
    if (getLp()?.length === 0) {
      replace('/swap');
    }
  }, [getLp()]);

  return (
    <>
      <PoolView>
        <Header
          title={t(`${PoolType[type]}`) + ' '}
          symbol={getLp()?.lpSymbol}
        />
        {type === 'stake' && <Pledge lp={getLp()} />}
        {type === 'redeem' && billId && <Redeem lp={getLp()} billId={billId} />}
      </PoolView>
      <Footer>Bridge assets to FIBO Chain</Footer>
    </>
  );
};
export default Pool;
