import React from 'react';
import styled from 'styled-components';
import { farmsType, farmsList } from '@/newPages/1230';
import { useTranslation } from 'react-i18next';
import AccordionList from './component/AccordionList';
import HeaderTab from '../component/headerTab';

export const FarmsListAppBody = styled.div`
  margin-top: 50px !important;
  /* IP6 */
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin-top: 0px !important;
  `};
`;

export const ListWrap = styled(FarmsListAppBody)`
  width: 1200px;
  margin: auto;
  color: ${({ theme }) => theme.primary1};
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 96%;
  `};
  .banner-main {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.primary1};
    font-weight: 600;
    .introduce {
      font-size: 24px;
      ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        font-size: 12px;
      `};
    }
    .title {
      font-size: 64px;
      margin-bottom: 24px;
      ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        font-size: 24px;
      `};
    }
  }
  & main {
    border-radius: 16px;
    overflow: auto;
    line-height: 22px;
    th {
      font-size: 20px;
    }
    td {
      font-weight: 600;
    }
  }
`;

interface IndexType {
  isVisible?: boolean;
  onClose?: () => any;
}

const Index: React.FC<IndexType> = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderTab />

      <ListWrap>
        <div className="banner-main">
          <div className="title">{t('Farms')}</div>
          <div className="introduce">
            {t('Stake LP to Earn')} FLY Farm & FIBO Farm
          </div>
        </div>
        <main>
          {farmsList?.map((item: farmsType) => {
            return <AccordionList data={item} key={item.pid} />;
          })}

          {farmsList.length === 0 && (
            <div className="flex-center">{t('Data Not Found')}</div>
          )}
        </main>
      </ListWrap>
    </>
  );
};
export default Index;
