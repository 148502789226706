import React, { Suspense } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import styled from 'styled-components';
import GoogleAnalyticsReporter from '@/components/analytics/GoogleAnalyticsReporter';
import Header from '@/components/Header';
import Popups from '@/components/Popups';
import Web3ReactManager from '@/components/Web3ReactManager';
import DarkModeQueryParamReader from '@/theme/DarkModeQueryParamReader';
import AddLiquidity from './AddLiquidity';
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './AddLiquidity/redirects';
import MigrateV1 from './MigrateV1';
import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange';
import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange';
import Liquidity from './Liquidity';
import PoolFinder from './PoolFinder';
import RemoveLiquidity from './RemoveLiquidity';
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects';
import Swap from './Swap';
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects';

import Farms from '@/newPages/farms/list';
import Pledge from '@/newPages/farms/pledge';
import HistoricalBill from '@/newPages/farms/historicalBill';
import Mapping from '@/newPages/farms/mapping';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  height: 100vh;
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  /* padding-top: 200px; IP6 */
  /* overflow-y: auto;
  overflow-x: hidden; */
  /* z-index: 10;
  z-index: 1; */
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

export default function App() {
  return (
    <Suspense fallback={null}>
      {/* <ToastContainer /> */}

      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Switch>
                <Route exact strict path="/swap" component={Swap} />
                <Route
                  exact
                  strict
                  path="/swap/:outputCurrency"
                  component={RedirectToSwap}
                />
                <Route
                  exact
                  strict
                  path="/send"
                  component={RedirectPathToSwapOnly}
                />
                <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/liquidity" component={Liquidity} />
                <Route
                  exact
                  strict
                  path="/create"
                  component={RedirectToAddLiquidity}
                />
                <Route exact path="/add" component={AddLiquidity} />
                <Route
                  exact
                  path="/add/:currencyIdA"
                  component={RedirectOldAddLiquidityPathStructure}
                />
                <Route
                  exact
                  path="/add/:currencyIdA/:currencyIdB"
                  component={RedirectDuplicateTokenIds}
                />
                <Route
                  exact
                  strict
                  path="/remove/v1/:address"
                  component={RemoveV1Exchange}
                />
                <Route
                  exact
                  strict
                  path="/remove/:tokens"
                  component={RedirectOldRemoveLiquidityPathStructure}
                />
                <Route
                  exact
                  strict
                  path="/remove/:currencyIdA/:currencyIdB"
                  component={RemoveLiquidity}
                />
                <Route exact strict path="/migrate/v1" component={MigrateV1} />
                <Route
                  exact
                  strict
                  path="/migrate/v1/:address"
                  component={MigrateV1Exchange}
                />

                {/* new Page */}

                {/* farms */}
                <Route exact strict path="/farms" component={Farms} />
                <Route
                  exact
                  strict
                  path="/farms/historicalBill"
                  component={HistoricalBill}
                />
                <Route
                  exact
                  strict
                  path="/farms/pledge/:type/:pid"
                  component={Pledge}
                />
                <Route
                  exact
                  strict
                  path="/farms/pledge/:type/:pid/:billId"
                  component={Pledge}
                />
                <Route exact strict path="/farms/mapping" component={Mapping} />

                {/* Pool */}

                <Route component={RedirectPathToSwapOnly} />
              </Switch>
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  );
}
