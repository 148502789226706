import React, { useState, memo } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { ExternalLink as LinkIcon } from 'react-feather';
import TokenNum from '@/newPages/farms/component/TokenNum';
import { useGetLp } from '@/newPages/farms/hooks';
import {
  Link,
  Item,
  Title,
  HeaderContent,
  AccordionContent,
  AccordionContentBox,
  AccordionContentBoxTitle,
  DetailsBtn,
  RowRprView,
  HeaderAPR,
  BlockReward,
  AccordionContentBlockReward,
  LpTokenName,
} from './styles';
import { useTranslation } from 'react-i18next';
import TvlView from '../../../component/tvl';
import Loader from '@/components/Loader';
import { farmsType, FLY, FUSD, FUSDToken } from '@/newPages/1230';
import QuestionHelper from '@/components/QuestionHelper';
import { isFiboToken } from '@/newPages/farms/utils';
import styled from 'styled-components';
import { DEFAULT_INPUT_CURRENCY } from '@/connectors';
import { ButtonPrimary } from '@/components/Button';
import FarmsBtn from '../farmsBtn';
import fibo_img from '@/assets/images/icon/fibo_icon.png';
import { tryParseAmount } from '@/state/swap/hooks';
import { useTradeExactIn } from '@/hooks/Trades';
import { ETHER } from 'aiswap-sdk';
import { BigNumber } from 'bignumber.js';
import { useLptokenNum } from '@/newPages/farms/component/tvl';
import { BLOCKS_PER_YEAR, PRECISION } from '@/constants';

type FViewType = {
  title: string;
  address: string;
  flyReward: string;
  fiboReward: string;
  tooltip?: string;
};

export const PledgeBtn = styled(ButtonPrimary)`
  height: 36px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 600;
  margin: auto;
  white-space: nowrap;
`;

const FView = memo(
  ({ title, address, flyReward, fiboReward, tooltip }: FViewType) => {
    return (
      <div>
        <Title>
          {title} {tooltip && <QuestionHelper text={tooltip} />}
        </Title>
        <div>
          <div style={{ width: '120px' }} className="flex-item-center">
            FFLY:
            <TokenNum address={address} num={flyReward} />
          </div>
          <div style={{ width: '120px' }} className="flex-item-center">
            FFIBO:
            <TokenNum address={address} num={fiboReward} />
          </div>
        </div>
      </div>
    );
  },
);

// 块奖励;
const BlockRewardContent = (props: any) => {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <FView
      title={t('block reward')}
      address={data?.lpToken}
      flyReward={data?.multiplier}
      fiboReward={data?.multiplierFIBO}
      tooltip={t(
        'The block  reward  the proportion of FLY/FIBO rewards each farm receives, as a proportion of the FLY/FIBO produced each block. For example, if a 1x farm received 1 FLY/FIBO per block, a 40x farm would receive 40 FLY/FIBO per block.',
      )}
    />
  );
};

// apr
const AprView = (props: any) => {
  const { data } = props;
  // 1fibo
  const FIBOToken = tryParseAmount(
    BigNumber(data.multiplierFIBO)
      .div(10 ** ETHER.decimals)
      .toFixed(ETHER.decimals),
    ETHER,
  );
  const FlyToken = tryParseAmount(
    BigNumber(data.multiplier)
      .div(10 ** 18)
      .toFixed(FLY.decimals),
    FLY,
  );
  // fibo=>usd
  const FiboUsd = useTradeExactIn(FIBOToken ?? undefined, FUSD); // 数量对比目标token
  const FlyUsd = useTradeExactIn(FlyToken ?? undefined, FUSD); // 数量对比目标token

  const FiboUsdPrice =
    FiboUsd?.executionPrice && FIBOToken
      ? BigNumber(FiboUsd.executionPrice.toSignificant()).times(
          FIBOToken.toSignificant(),
        )
      : '';

  const FlyUsdPrice =
    FlyUsd?.executionPrice && FlyToken
      ? BigNumber(FlyUsd.executionPrice.toSignificant()).times(
          FlyToken.toSignificant(),
        )
      : '';

  // token 总金额
  const tokenPrice = BigNumber(FiboUsdPrice.toString()).plus(
    FlyUsdPrice.toString(),
  );

  // lp总金额(U)
  const { loading, Price }: any = useLptokenNum(
    data,
    data?.lpBalanceOf.toString(), // 总lp金额
    // data?.userTicket.toString(), // 用户总lp金额
  );

  // console.log(
  //   tokenPrice.toString(),
  //   Price?.toString(),
  //   'Price',
  //   (
  //     (Number(Price?.toString()) *
  //       Number(tokenPrice.toString()) *
  //       BLOCKS_PER_YEAR *
  //       100) %
  //     100
  //   ).toFixed(PRECISION),
  //   `BLOCKS_PER_YEAR:${BLOCKS_PER_YEAR}`,
  // );

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {/* {(
        (Number(tokenPrice?.toString()) * BLOCKS_PER_YEAR) /
        Number(Price?.toString())
      ).toFixed(PRECISION)} */}
      {BigNumber(tokenPrice?.toString())
        .times(BLOCKS_PER_YEAR)
        .div(Price?.toString())
        .toFixed(PRECISION)}
      %
    </>
  );
};

const Logo = styled.img`
  width: 100%;
`;

const QuoteTokenView = styled.div`
  position: absolute;
  width: 50%;
  bottom: auto;
  left: 1px;
  right: auto;
  top: 1px;
  z-index: 5;
  max-height: 20px;
  max-width: 20px;
`;
const TokenView = styled.div`
  position: absolute;
  width: 82%;
  bottom: 0;
  left: auto;
  right: 0;
  top: auto;
  z-index: 6;
  & > img {
    width: 82%;
  }
`;
const LplogoView = styled.div`
  width: 40px;
  height: 40px;
  flex: none !important;
  position: relative;
  margin-right: 6px;
`;

export const Lplogo = (props: AccordionListType) => {
  const { data } = props;

  const setUrl = (token: any) => {
    let adderss = token?.address;
    if (token?.symbol === 'FUSD') {
      adderss = FUSDToken;
    }
    const symbol =
      token?.symbol === DEFAULT_INPUT_CURRENCY
        ? token?.symbol
        : `${token?.symbol}_${adderss}`;

    return `https://chainfb.oss-cn-hangzhou.aliyuncs.com/ETH/12306/${symbol}.png`;
  };
  const url = [setUrl(data.quoteToken), setUrl(data.token)];

  return (
    <LplogoView>
      <QuoteTokenView>
        <Logo src={url[0]} alt="" />
      </QuoteTokenView>
      <TokenView>
        <Logo src={url[1]} alt="" />
      </TokenView>
    </LplogoView>
  );
};

interface AccordionListType {
  data: farmsType;
}

const AccordionList = (props: AccordionListType) => {
  const { data } = props;
  const { t } = useTranslation();
  const [Stow, setStow] = useState<boolean>(false);
  const item: any = useGetLp(data);
  const BlockRewardContentView = () => <BlockRewardContent data={item} />;

  // !item.loading ? <BlockRewardContent data={item} /> : <Loader />;

  const Apr = () => (
    <div>{!item.loading ? <AprView data={item} /> : <Loader />}</div>
  ); //'❓';
  return (
    <Item>
      <HeaderContent>
        <LpTokenName>
          <Lplogo data={data} />
          <span>{data?.lpSymbol}</span>
        </LpTokenName>
        <HeaderAPR>
          APR: <Apr />
        </HeaderAPR>
        <FView
          title={t('Sum Rewards')}
          address={item?.lpToken}
          flyReward={item?.userFlyRewardInLp}
          fiboReward={item?.userFiboRewardInLp}
        />

        <div>
          <Title>TVL</Title>
          <div>{!item.lpBalanceOf ? <Loader /> : <TvlView data={item} />}</div>
        </div>
        <RowRprView>
          <Title>APR</Title>
          <Apr />
        </RowRprView>

        <BlockReward>
          <BlockRewardContentView />
        </BlockReward>

        <DetailsBtn onClick={() => setStow(!Stow)} className="cursor">
          {!Stow ? <ChevronDown /> : <ChevronUp />}
        </DetailsBtn>
      </HeaderContent>
      {/*  */}
      <AccordionContent Stow={Stow}>
        <div>
          <AccordionContentBlockReward>
            <BlockRewardContentView />
          </AccordionContentBlockReward>
          <Link
            href={`#/add/${isFiboToken(data?.quoteToken) ??
              undefined}/${isFiboToken(data?.token) ?? undefined}`}
          >
            {t('Get')} {data?.lpSymbol}&nbsp;
            <LinkIcon size={16} />
          </Link>
          <Link href={`https://scan.fibochain.org/address/${item?.lpToken}`}>
            {t('View contract')}&nbsp;
            <img src={fibo_img} style={{ width: '14px' }} />
          </Link>
        </div>
        <AccordionContentBox>
          <AccordionContentBoxTitle>
            {t('Start Farming')}
          </AccordionContentBoxTitle>
          <FarmsBtn data={data} isShowOpen={false} height="36px"></FarmsBtn>
        </AccordionContentBox>
      </AccordionContent>
    </Item>
  );
};

export default AccordionList;
