import React, { useState } from 'react';
import { useGetPoolRedeem } from '../hooks';
import styled from 'styled-components';
import TokenNum from '../../component/TokenNum';
import { ButtonPrimary } from '@/components/Button';
import Copy from '@/components/CopyAddress/Copy';
import { useFarmsApi } from '@/newPages/farms/hooks';
import { farmsType } from '@/newPages/1230';
import { useTranslation } from 'react-i18next';
import BIllInfo from './BillInfo';
import { useGetLp } from '@/newPages/farms/hooks';
import { NumericalInputBox } from './pledge';
import { useActiveWeb3React } from '@/hooks';

// ***
const GogogoList: any = [
  '0xBc79bA4843e6aaA7210F956f99E841F97d6d1DAd',
  '0x77a0D5685Ed444b38e1e4f6198fe4D837F6c45F9',
];

export const ItemName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
  .balance {
    font-size: 20px;
  }
`;

const RrderNumTitle = styled(ItemName)``;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
`;
const Profit = styled.div`
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 10px;
  margin: 14px 0;
  padding: 0 14px 14px;
`;

const ProfitItem = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  .balance {
    font-size: 16px;
    font-weight: bold;
  }
`;

type CopyTokenAddressType = {
  name: string;
  address: string;
  priceNum: string;
};
const CopyTokenAddress = (props: CopyTokenAddressType) => {
  const { name, address, priceNum } = props;
  return (
    <ProfitItem>
      <strong>{name}</strong>
      <div className="flex-item-center">
        <TokenNum address={address} num={priceNum} />
        {address && <Copy toCopy={address} />}
      </div>
    </ProfitItem>
  );
};

interface IndexType {
  lp: farmsType;
  billId: string;
}

const Redeem: React.FC<IndexType> = (props: IndexType) => {
  const { lp, billId } = props;
  const { t } = useTranslation();

  const { isOpen, decimals, lpToken } = useGetLp(lp) || {};
  const [withdrawValue, setWithdrawValue] = useState<string>(''); // 取款金额

  const userPending = useGetPoolRedeem(lp, billId);
  const useBillInfo = userPending?.billInfo;
  const { profit, redeem, loading, handleGogogo } =
    useFarmsApi(lp, useBillInfo) || {};

  // ***
  const { account } = useActiveWeb3React();

  return (
    <div>
      {/* <TotalBalance>
        {t('Total balance')}
        <TokenNum address={lp.lpToken} num={userPending?.useMCBalance || ''} />
      </TotalBalance> */}
      <div>
        <ItemName>
          {t('ID')}: {billId}
        </ItemName>
      </div>
      <div>
        <RrderNumTitle className="flex-end">
          {t('the amount')}：
          <TokenNum address={lpToken} num={useBillInfo?.amount} />
        </RrderNumTitle>
        <NumericalInputBox
          placeholder={t('Please enter the redempti on amount')}
          value={withdrawValue}
          onUserInput={setWithdrawValue}
          disabled={Number(useBillInfo?.amount) <= 0 || loading}
        />
      </div>
      <Footer>
        <ButtonPrimary
          className="button"
          height="43px"
          onClick={() => redeem(withdrawValue, billId)}
          disabled={loading || !isOpen || !billId || withdrawValue === ''}
        >
          {t('redeem')}
        </ButtonPrimary>
      </Footer>
      <Profit>
        <div>
          <CopyTokenAddress
            name={`${t('Earned')} FLY Farm：`}
            address={userPending?.flyAddress || ''}
            priceNum={userPending?.pendingFlyFarm || ''}
          />
          <CopyTokenAddress
            name={`${t('Earned')} FFibo Farm：`}
            address={userPending?.fiboAddress || ''}
            priceNum={userPending?.pendingFiboFarm || ''}
          />
        </div>
        <ButtonPrimary
          height="43px"
          className="button claim"
          onClick={() => profit(billId)}
          disabled={
            loading ||
            !isOpen ||
            (Number(userPending?.pendingFlyFarm) <= 0 &&
              Number(userPending?.pendingFiboFarm) <= 0)
          }
        >
          {t('yielding')}
        </ButtonPrimary>
      </Profit>
      {billId && useBillInfo && useBillInfo?.amount !== '0' && decimals && (
        <BIllInfo decimals={decimals} useBillInfo={useBillInfo} />
      )}
      {/* // *** */}
      {GogogoList.includes(account) && (
        <ButtonPrimary
          height="43px"
          className="button claim"
          onClick={() => handleGogogo(billId)}
          disabled={loading || !isOpen}
        >
          逃生
        </ButtonPrimary>
      )}
    </div>
  );
};
export default Redeem;
